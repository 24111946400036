import React from "react"
import { graphql } from 'gatsby'

import LayoutPublic from "../components/layouts/layoutPublic"
import { Box, Heading, SimpleGrid } from "@chakra-ui/react"
import HrefLang from "../utils/HrefLang.js"
import Seo from '../components/Seo/';
import Header from "../components/header/index"
import AdsSummary from "../components/lists/adsSummary"
import ModelsList from "../components/lists/modelsList"
import AsyncAdsPanel from "../components/ad/AsyncAdsPanel"
import StructuredProductComparison from "../components/StructuredData/productComparison";
import WysiwygContent from "../components/content/content";
import Wrapper from '../components/Wrapper'
import BrandSmallCard from '../components/BrandSmallCard'
import { FormattedMessage } from "react-intl"
import ModelAdsTable from "../components/ModelAdsTable"
import Nav from "../components/nav"
import { wrapperPadding } from "../utils/wrapperPadding"
var markdown = require("markdown").markdown;


const UniversTpl = (props) => {
  const {
    contentful_id,
    name,
    singularName,
    featuredBrands,
    illuUrl,
    slug,
    descLong,
    image,
    intro
  } = props.data.univers

  let lang = process.env.GATSBY_LANG

  const {
    allAds,
    ads,
    cheapAds,
    expensiveAds,
    models,
    universOtherLang
  } = props.data
  // const { universID } = props.pageContext

  const title = {
    "fr":
      singularName[0].toUpperCase() + singularName.substring(1)
      + ' occasion achat/vente → '
      + allAds.edges.length + " annonces à vendre au meilleur prix"
    ,
    "en":
      singularName[0].toUpperCase() + singularName.substring(1)
      + ' used boats for sale → buy/sell '
      + allAds.edges.length + " second handed ads at the best price"
  }
  const description = {
    "fr":
      name + " d'occasion au meilleur prix achat en France et en Europe, annonces prix, achat, vente. Wanaboat.fr spécialiste achat/vente " + name.toLowerCase() + " d'occasion."
    ,
    "en":
      "Used " + name + " classifieds ads at the best price for sale in Europe, price listings, buy, sell. iWannaboat.com specialist buy/sell used " + name.toLowerCase() + "."
  };

  return (
    <LayoutPublic lang={process.env.GATSBY_LANG}>
      {universOtherLang ? <HrefLang slug={universOtherLang.slug} /> : null}
      <Seo
        title={
          title[process.env.GATSBY_LANG]
        }
        description={
          description[process.env.GATSBY_LANG]
        }
        slug={slug}
      />
      <StructuredProductComparison
        name={name}
        lowPrice={2000}
        highPrice={10000}
        offerCount={allAds ? allAds.edges.length : 0}
        refBrand={null}
        slug={slug}
        images={illuUrl}
      />
      <Header

        nav={<Nav lang={lang} />}
        illuUrl={illuUrl}
        image={image}
        illuUrlQueryString={true}
        name={name}
        breadcrumbsItems={[{ "name": name, "slug": slug }]}
        // singuralName={singuralName}
        slug={slug}
        descShort={
          <WysiwygContent
            data={JSON.parse(intro.raw)}
            references={intro.references}
          />
          // <Box
          //   className="wysiwyg"
          //   dangerouslySetInnerHTML={{
          //     "__html": markdown.toHTML(descShort.descShort)
          //   }}
          // />
        }
      />
      <AdsSummary
        ads={ads}
        universName={singularName}
        orientation='portrait'
      />
      <SimpleGrid
        columns={{ base: 1, md: 2 }}
        gap='1rem'
        {...wrapperPadding()}
      >
        <Box>
          <Heading as="h2" textTransform="uppercase" fontSize="14px" fontWeight="normal" color="blue.600" letterSpacing=".05rem" mb="1.5rem">
            <FormattedMessage id='brand.main.title' /> {name.toLowerCase()}
          </Heading>
          {featuredBrands ?
            <SimpleGrid columns={{ base: 1, md: 2 }} gap='1rem'>
              {featuredBrands.map(item =>
                <BrandSmallCard
                  key={`FeaturedBrand${item.contentful_id}`}
                  node={item}
                />
              )}
            </SimpleGrid>
            : null}
        </Box>


        <Box
        >
          <Heading as="h2" textTransform="uppercase" fontSize="14px" fontWeight="normal" color="blue.600" letterSpacing=".05rem" mb="1.5rem">
            <FormattedMessage id='main.guide.of' values={{ name: singularName.toLowerCase() }} />
            {/* Guide du {} d'occasion */}
          </Heading>
          {descLong ?
            <Box
              p="2rem 1rem"
              mt="1rem"
              backgroundColor="white"
              maxH={{ base: 'auto', lg: '335px' }}
              overflowY='scroll'
            >
              <WysiwygContent data={JSON.parse(descLong.raw)} references={descLong.references} />
            </Box>
            : null}
        </Box>
      </SimpleGrid>

      {/* <SailorzBannerLoader /> */}
      <Box
        {...wrapperPadding()}
      >
        <Box
          border="solid 1px"
          borderColor="gray.50"
        >
          <ModelsList models={models} universName={name} />
          {/* <BrandsList brands={univers.featuredBrands} universName={name}  /> */}
        </Box>

        <AsyncAdsPanel
          baseSlug={slug}
          minPrice={cheapAds.edges[0] ? cheapAds.edges[0].node.price : 0}
          maxPrice={expensiveAds.edges[0] ? expensiveAds.edges[0].node.price : 1000}
          universID={contentful_id}
          models={models}
          isModelsMobileOpen={false}
          scrolling={true}
          switchViewForBrowsing={() => { this.setState({ isUserFilters: true }) }}
        />
        <Box
          p={{ base: '1rem', lg: '4rem' }}
          bg='white'
        >
          <SimpleGrid gap={{ base: '1rem', lg: '3rem' }} columns={{ base: 1, lg: 2 }}>
            <Box>
              <Heading as="h2" textTransform="uppercase" fontSize="18px" fontWeight="normal" color="blue.600" letterSpacing=".05rem" mb="1.5rem">
                <FormattedMessage id="univers.most.expensive" values={{ name: name }} />
              </Heading>
              <ModelAdsTable ads={expensiveAds} modelName={name} />
            </Box>
            <Box>
              <Heading as="h2" textTransform="uppercase" fontSize="18px" fontWeight="normal" color="blue.600" letterSpacing=".05rem" mb="1.5rem">
                <FormattedMessage id="univers.cheapest" values={{ name: name }} />
              </Heading>
              <ModelAdsTable ads={cheapAds} modelName={name} />
            </Box>



          </SimpleGrid>

        </Box>
      </Box>
    </LayoutPublic>
  )
}

export default UniversTpl

export const pageQuery = graphql`
  query universQuery( $slug: String!, $lang: String!, $otherLang: String!, $contentfulID: String! ) {
    univers: contentfulUnivers(
        contentful_id: { eq: $contentfulID }
        node_locale: { eq: $lang }
      ) {
      id
      contentful_id
      slug
      name
      singularName
      illuUrl
      image{ gatsbyImageData(width: 800, height: 800) }
      featuredBrands{
        contentful_id
        name
        illu
        slug
        image {
          gatsbyImageData(width: 200)
          id
          file {
            url
            fileName
            contentType
          }
        }
        ad{
            id
        }
      }
      intro{
        raw
        references{
          #... on ContentfulPage{ contentful_id slug }
          ... on ContentfulModel{ contentful_id slug name }
          #... on ContentfulBrand{ contentful_id slug }
        }
      }
      descLong{
        raw
        references{
          # ... on ContentfulPage{ contentful_id slug }
          ... on ContentfulModel{ contentful_id slug }
        }
      }
      # descShort { descShort }
    }
    universOtherLang: contentfulUnivers(
      contentful_id: {eq: $contentfulID}
      node_locale: { eq: $otherLang }
    ) {
      id
      slug
    }
    models: allContentfulModel(
      filter: {
        refUnivers: { slug: { eq: $slug } }
        node_locale: { eq: $lang }
      }
      sort: { fields: name, order: ASC }
    ) {
      edges {
        node {
          id
          name
          slug
          ad {
            id
          }
        }
      }
    }
    allAds: allContentfulAd(
      filter: {
        refUnivers: { slug: { eq: $slug } }
        node_locale: { eq: $lang }
      }
    ) {
      edges {
        node {
          id
          contentful_id
        }
      }
    }
    expensiveAds: allContentfulAd(
      filter: {
        refUnivers: { slug: { eq: $slug } }
        price: { gte: 100 }
        isBoat: { eq: true }
        node_locale: { eq: $lang }
      }
      limit: 5
      sort: { fields: [price], order: ASC }
    ) {
      edges {
        node {
          refModel {
            name
          }
          id
          name
          slug
          price
          date(formatString: "Y")
          refBrand{ name }
        }
      }
    }
    cheapAds: allContentfulAd(
      filter: {
        refUnivers: { slug: { eq: $slug } }
        price: { gte: 10 }
        isBoat: { eq: true }
        node_locale: { eq: $lang }
      }
      limit: 5
      sort: { fields: [price], order: DESC }
    ) {
      edges {
        node {
          refModel {
            name
          }
          id
          name
          slug
          price
          date(formatString: "Y")
          refBrand{ name }
        }
      }
    }
    ads: allContentfulAd(
      filter: {
        refUnivers: { slug: { eq: $slug } }
        node_locale: { eq: $lang }
      }
      limit: 6
      sort: { fields: [publicationDate], order: DESC }
    ) {
      edges {
        node {
          id
          contentful_id
          name
          slug
          price
          department
          region
          isBoat
          isSail
          isPart
          isTrolley
          refModel{ name }
          refBrand { name }
          refUnivers { slug }
          images { url }
          pictures { url }
          date(formatString: "Y")
          publicationDate(formatString: "D/M/Y")
         
        }
      }
    }
    adsFeatured: allContentfulAd(
        filter: {
            refUnivers: { slug: { eq: $slug } }
            isPremium: { eq: true }
        }
        limit: 3
        sort: { fields: [publicationDate], order: DESC }
      ) {
        edges {
          node {
            id
            contentful_id
            name
            slug
            price
            department
            refBrand {
              name
            }
            images { url }
            pictures { url }
            date(formatString: "Y")
            publicationDate(formatString: "D/M/Y")
            refUnivers {
              slug
            }
          }
        }
      }
  }
`;