import React from 'react'
import { Link as GatsbyLink } from 'gatsby'
import {
    Box,
    Image,
    Grid,

    Text,
    Flex
} from '@chakra-ui/react'

const BrandSmallCard = ({ node }) => {
    return (
        <Box
            as={GatsbyLink}
            to={`${node.slug}`}
            bg='white'
            cursor='pointer'
            shadow="xs"
            borderRadius='3px'
            _hover={{
                boxShadow: "0 0 0px 2px rgba(0,66,218,.7)"
            }}
        >
            <Grid
                templateColumns='70px 1fr'
                gap='.25rem'
            >
                <Box>
                    {node.image ?
                        node.image.file ?
                            <picture>
                                <source srcSet={`https:${node.image.file.url}?w=70&h=70&fm=webp`} type="image/webp" />
                                <source srcSet={`https:${node.image.file.url}?w=70&h=70&fm=jpg`} type="image/jpeg" />
                                <Image
                                    w='70px'
                                    h='70px'
                                    htmlWidth={1}
                                    htmlHeight={1}
                                    objectFit='cover'
                                    src={`https:${node.image.file.url}?w=70&h=70&fm=jpg`}
                                    loading='lazy'
                                    alt={node.name}
                                />
                            </picture>
                            : null
                        : null}
                </Box>
                <Flex
                    alignItems='center'
                    p='.5rem'
                    wrap='wrap'
                    h='100%'
                >
                    <Box>
                        <Text
                            textTransform='uppercase'
                            color='gray.800'
                        >
                            {node.name}
                        </Text>
                        <Text
                            color='gray.500'
                        >
                            {node.ad ?
                                `${node.ad.length} annonces`
                                : null}
                        </Text>
                    </Box>

                </Flex>
            </Grid>



        </Box>
    )
}

export default BrandSmallCard