import React, { Component } from 'react';
import { Link as GatsbyLink } from 'gatsby';
import { FormattedMessage } from 'react-intl';


import {
    Box,
    Button,
    Flex,
    Heading,
    Stack,
    Link,
    SimpleGrid
} from "@chakra-ui/react";

class ModelsList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isDetailedView: false
        }
    }
    compare(a, b) {
        if (a.ad === null || b.ad === null) {
            return 0;
        }
        if (a.ad.length < b.ad.length) {
            return 1;
        }
        if (a.ad.length > b.ad.length) {
            return -1;
        }
        return 0;
    }
    getTopLinks(links) {
        var cleanLinksArray = []
        for (let index = 0; index < links.length; index++) {
            const element = links[index].node ? links[index].node : links[index];
            if (element && element.ad) {
                cleanLinksArray.push(element)
            }
        }
        cleanLinksArray = cleanLinksArray.sort(this.compare)
        return cleanLinksArray
    }
    render() {
        const { isDetailedView } = this.state
        const { models } = this.props
        const { universName } = this.props
        const topLinks = this.getTopLinks(models.edges)
        return (
            <Box
                py={{ base: "1rem", md: "2rem" }}
                px={{ base: "1rem", md: "1rem", lg: "2rem" }}
                background="rgba(255,255,255, 0.8)"
            >
                <Flex
                    alignItems='center'
                    mb='1rem'
                    wrap='wrap'
                >
                    <Heading
                        as="h3"
                        fontWeight="bold"
                        fontSize={{ base: "13px" }}
                        textTransform="uppercase"
                    >
                        {universName ?
                            <FormattedMessage id="models.list.title" values={{ universName: universName }} />
                            :
                            <FormattedMessage id="models.list.title.generic" values={{ universName: universName }} />
                        }
                    </Heading>
                    <Button
                        ml={{ lg: '1rem' }}
                        size="xs"
                        onClick={() => { this.setState({ isDetailedView: isDetailedView ? false : true }) }}
                    >
                        {isDetailedView ? <FormattedMessage id="cta.see.less.models" /> : <FormattedMessage id="cta.see.all.models" />}
                    </Button>
                </Flex>

                <Stack
                    spacing={"1rem"}
                >
                    <Box
                        overflowY={{ base: "scroll", lg: "auto" }}
                        height="100%"
                    >
                        {!isDetailedView ?
                            <SimpleGrid
                                columns={{ base: 2, md: 3, xl: 4 }}
                            >
                                {topLinks.map((edge, i) =>
                                    i <= 15 ?
                                        <Link as={GatsbyLink}
                                            textDecor={!edge.ad ? "line-through" : "none"}
                                            fontSize={{ base: "13px", xl: "15px" }}
                                            display="block"
                                            key={edge.id}
                                            color='blue.brand'
                                            to={edge.slug}

                                            aria-label={`Les annonces ${edge.name}`}
                                        >
                                            {edge.name}
                                            {(edge.ad) ? ` — (${edge.ad.length})` :
                                                null}
                                        </Link>
                                        : null
                                )}
                            </SimpleGrid>
                            :
                            <SimpleGrid
                                columns={{ base: 2, md: 3, xl: 4 }}
                            >
                                {models.edges.map((edge, i) =>
                                    <Link as={GatsbyLink}
                                        textDecor={!edge.node.ad ? "line-through" : "none"}
                                        fontSize={{ base: "13px", xl: "15px" }}
                                        display="block"
                                        key={edge.node.id}
                                        color='blue.brand'
                                        to={edge.node.slug}

                                        aria-label={`Les annonces ${edge.node.name}`}
                                    >
                                        {edge.node.name}
                                        {(edge.node.ad) ? ` — (${edge.node.ad.length})` :
                                            null}
                                    </Link>
                                )}
                            </SimpleGrid>
                        }
                    </Box>
                </Stack>
            </Box >
        )
    }
}

export default ModelsList